.success-box {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(255, 255, 255);
  padding-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1em 1em 0 0;
  box-shadow: 0px 0px 10px rgb(141, 141, 141);
  animation: fadeInOut 3s ease-in-out forwards;
  z-index: 1000;
}

.medal-image {
  width: 8em;
  height: 8em;
}

.motivational-text {
  text-align: center;
  color: #333;
}

.moving-growing-image {
  position: absolute;
  width: 8em;
  height: 8em;
  animation: moveAndGrowArc 0.8s ease-in-out forwards, fadeOut 0.3s ease-in-out 0.6s forwards;
  z-index: 1000;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  10% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
}

@keyframes moveAndGrowArc {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(calc((var(--end-left) - 60%) / 2), calc((var(--end-top) - 150%) / 2 - 30vh)) scale(0.75);
  }
  100% {
    top: var(--end-top);
    left: var(--end-left);
    transform: translate(-50%, -50%) scale(0.25);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
